import { IWithId } from "Interfaces";
import { IIndexSignature } from "@clintonelec/typescriptutils";

export interface IErrorResult {
	errors: IError[];
}

export interface IError {
	status: string;
	code?: string;
	source: { pointer: string };
	title: string;
	detail: string;
}

export enum ResourceType {
	USERS = "users",
	HOSTNAMES = "hostnames",
	AUTH = "auth"
}

export interface IApiData {
	type?: ResourceType;
	id?: string;
}

export interface IWithApiData<Attributes> extends IWithDeserializedData {
	data: Partial<IApiResource<Attributes>>;
}

export interface IApiResource<Attributes> extends IWithId, IWithDeserializedData {
	type: ResourceType;
	id: string;
	attributes: Attributes;
	relationships?: {
		updater?: {
			links?: {
				self?: string;
				related?: string;
			};
			data?: IApiData;
		};
	};
}

export interface IWithDeserializedData {
	links?: {
		self: string;
	};
	meta?: IIndexSignature<unknown>;
}
