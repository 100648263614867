import { GET, POST } from "Data/API/Request";
import {
	IApiUser, IForgotPasswordPayload, ILoginUserPayload, IResetPasswordPayload, IWithApiData, UserMeta,
	IRegisterUserPayload, ResourceType
} from "Interfaces";
import { deserializeJsonApiData, serializeJsonApiData } from "Data/Utils/JsonApi";

export const loginUser = (loginUserPayload: ILoginUserPayload) => {
	return POST("auth/login", loginUserPayload).then((response: IWithApiData<IApiUser>) => {
		return deserializeJsonApiData<IApiUser>(response).then((apiUser: IApiUser) => {
			const { id, email, name, verified, meta } = apiUser;

			return {
				user: {
					id,
					email,
					name,
					verified
				},
				token: meta?.[ UserMeta.TOKEN ],
				pusherKey: meta?.[ UserMeta.PUSHER_KEY ],
				hostnameLimit: meta?.[ UserMeta.HOSTNAME_LIMIT ]
			};
		});
	});
};

export const registerUser = (registerUserPayload: IRegisterUserPayload) => {
	const serializedPayload = serializeJsonApiData(ResourceType.USERS, registerUserPayload);

	return POST(
		"auth/register",
		serializedPayload
	);
};

export const forgotPassword = (payload: IForgotPasswordPayload) => {
	return POST(
		"auth/forgot-password",
		payload
	);
};

export const resetPassword = (payload: IResetPasswordPayload) => {
	return POST(
		"auth/reset-password",
		payload
	);
};

export const verifyEmail = (id: string, emailHash: string, timestamp: string, signatureHash: string) => {
	return GET(`auth/verify-email/${ id }/${ emailHash }?expires=${ timestamp }&signature=${ signatureHash }`);
};
