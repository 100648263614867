// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-popover {
  background: white;
}
.user-popover > div {
  display: flex;
  width: 200px;
}
.user-popover > div > div {
  width: 100%;
}
.user-popover .user-popover-content-wrapper .cec-popover-children-wrap {
  max-width: 100%;
}
.user-popover .user-popover-content-wrapper .user-popover-row {
  -webkit-user-select: none;
          user-select: none;
}
.user-popover .user-popover-content-wrapper .user-popover-row:not(:first-child) {
  cursor: pointer;
}
.user-popover .user-popover-content-wrapper .user-popover-row:not(:first-child):hover {
  color: #8e8e8e;
}
.user-popover .user-popover-content-wrapper .user-popover-row > span {
  margin-right: 10px;
}
.user-popover-name {
  cursor: default;
  font-size: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap;
  word-break: break-word;
  word-wrap: break-word;
}
.user-popover-full-name {
  color: black;
  word-wrap: break-word;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UserPopover/UserPopover.less"],"names":[],"mappings":"AAEA;EACC,iBAAA;AADD;AAAA;EAIE,aAAA;EACA,YAAA;AADF;AAJA;EASE,WAAA;AAFF;AAPA;EAcG,eAAA;AAJH;AAVA;EAkBG,yBAAA;UAAA,iBAAA;AALH;AAOG;EACC,eAAA;AALJ;AAOI;EACC,cAAA;AALL;AAnBA;EA6BI,kBAAA;AAPJ;AAaA;EACC,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,mBAAA;EACA,sBAAA;EACA,qBAAA;AAXD;AAcA;EACC,YAAA;EACA,qBAAA;AAZD","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.user-popover {\n\tbackground: white;\n\n\t> div {\n\t\tdisplay: flex;\n\t\twidth: 200px;\n\t}\n\n\t> div > div {\n\t\twidth: 100%;\n\t}\n\n\t.user-popover-content-wrapper {\n\t\t.cec-popover-children-wrap {\n\t\t\tmax-width: 100%;\n\t\t}\n\n\t\t.user-popover-row {\n\t\t\tuser-select: none;\n\n\t\t\t&:not(:first-child) {\n\t\t\t\tcursor: pointer;\n\n\t\t\t\t&:hover {\n\t\t\t\t\tcolor: @gray-1;\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t> span {\n\t\t\t\tmargin-right: 10px;\n\t\t\t}\n\t\t}\n\t}\n}\n\n.user-popover-name {\n\tcursor: default;\n\tfont-size: 1.5em;\n\toverflow: hidden;\n\ttext-overflow: ellipsis;\n\tuser-select: none;\n\twhite-space: nowrap;\n\tword-break: break-word;\n\tword-wrap: break-word;\n}\n\n.user-popover-full-name {\n\tcolor: black;\n\tword-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
