import { IWithApiData, ResourceType } from "Interfaces";
import { Serializer, Deserializer } from "jsonapi-serializer";

export function serializeJsonApiData<T extends object>(type: ResourceType, item: T, id?: string) {
	const serializedData = new Serializer(type, {
		id: id ?? "",
		attributes: Array.isArray(item) ? Object.keys(item?.[0]) : Object.keys(item),
		keyForAttribute: "camelCase"
	});

	return serializedData.serialize(item);
}

export function deserializeJsonApiData<T>(json: IWithApiData<T>): Promise<T> {
	return new Deserializer({ keyForAttribute: "camelCase" }).deserialize(json);
}
