import { IIndexSignature } from "@clintonelec/typescriptutils";

export enum Features {
	REGISTER = "register"
}

export const getFeatureFlags = (isDevelop: boolean) => {
	return {
		[ Features.REGISTER ]: isDevelop
	};
};

export function isFeature(feature: Features, featureFlags: IIndexSignature<boolean>): boolean {
	return Object.prototype.hasOwnProperty.call(featureFlags, feature);
}

export function getLocalFeatureFlag(feature: Features, featureFlags: IIndexSignature<boolean>): boolean {
	if (isFeature(feature, featureFlags)) {
		return featureFlags[feature];
	}

	return false;
}

export const toggleFeature = (
	featureFlags: IIndexSignature<boolean>
) => <T, V>(feature: Features, withFlag: T, fallback: V) => {
	const featureCriteria = getLocalFeatureFlag(feature, featureFlags);

	return featureCriteria ? withFlag : fallback;
};
