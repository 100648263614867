import { persistStore } from "redux-persist";
import { buildStore, store } from "Data/Redux/Store";
import { logoutOnUnauthorized } from "Data/Redux/Middleware/Request";
import listenerMiddleware from "Data/Redux/Middleware/Listeners";
import userReducer from "Data/Reducers/User";
import hostnamesReducer from "Data/Reducers/Hostnames";

const reducerMap = {
	Hostnames: hostnamesReducer,
	User: userReducer
};

buildStore(reducerMap, [ listenerMiddleware.middleware, logoutOnUnauthorized ]);

export const persistor = persistStore(store);
