import {
	IUpdatePasswordPayload, IUpdateUserPayload, IUser, IWithApiData, ResourceType
} from "Interfaces";
import { PATCH, DELETE } from "Data/API/Request";
import { deserializeJsonApiData, serializeJsonApiData } from "Data/Utils/JsonApi";

export const resetAccountPassword = (payload: IUpdatePasswordPayload) => {
	return PATCH(
		"account/password",
		serializeJsonApiData(ResourceType.USERS, payload)
	);
};

export const updateAccount = (payload: IUpdateUserPayload) => {
	return PATCH(
		"account/profile",
		serializeJsonApiData(ResourceType.USERS, payload)
	).then((response: IWithApiData<IUser>) => {
		return deserializeJsonApiData<IUser>(response);
	});
};

export const deleteAccount = () => {
	return DELETE("account");
};
