export const ACTION_TYPES = {
	Hostname: {
		CREATE_HOSTNAME: "CREATE_HOSTNAME",
		UPDATE_HOSTNAME: "UPDATE_HOSTNAME",
		DELETE_HOSTNAME: "DELETE_HOSTNAME",
		FETCH_HOSTNAME: "FETCH_HOSTNAME",
		FETCH_HOSTNAME_PAGE: "FETCH_HOSTNAME_PAGE",
		SET_HOSTNAME_OPTIONS: "SET_HOSTNAME_OPTIONS"
	},
	Navigation: {
		SET_ACTIVE_PAGE: "SET_ACTIVE_PAGE"
	},
	System: {
		CLEAR_CACHE: "CLEAR_CACHE"
	},
	Ui: {
		SET_SELECTED_HOSTNAMES: "SET_SELECTED_HOSTNAMES"
	},
	User: {
		LOGOUT_USER: "LOGOUT_USER",
		LOGIN_USER_ASYNC: "LOGIN_USER_ASYNC",
		UPDATE_ACCOUNT: "UPDATE_ACCOUNT",
		DELETE_ACCOUNT: "DELETE_ACCOUNT",
		UPDATE_PASSWORD: "UPDATE_PASSWORD",
		RESET_PASSWORD: "RESET_PASSWORD",
		REGISTER_USER: "REGISTER_USER"
	}
};
