import { memo, useState, FormEvent, Fragment } from "react";
import { Button, Input, Modal } from "@clintonelec/react-storybook";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import { createHostnameAction } from "Data/Actions/Hostnames";
import { IHostname, IWithChildren } from "Interfaces";
import "Components/HostnameModal/HostnameModal.less";
import Icon, { IconWeight } from "Components/Icon";
import { NotificationType, notify } from "src/Notifications";
import { SerializedError } from "@reduxjs/toolkit";
import { selectHostnameLimit } from "Data/Selectors/User";
import { selectHostnameCount } from "Data/Selectors/Hostnames";
import { IIndexSignature } from "@clintonelec/typescriptutils";

interface IHostnameFormElement extends HTMLFormElement {
	hostname: HTMLInputElement;
	ipv4: HTMLInputElement;
	ipv6: HTMLInputElement;
}

const HostnameModal = (props: IWithChildren) => {
	const dispatch = useAppDispatch();
	const [ modalVisible, setModalVisible ] = useState(false);
	const [ submitted, setSubmitted ] = useState(false);
	const [ errors, setErrors ] = useState<IIndexSignature<string>>({});
	const createHostname = (hostname: Partial<IHostname>) => dispatch(createHostnameAction(hostname));
	const hostnameLimit = useAppSelector(selectHostnameLimit);
	const hostnameCount = useAppSelector(selectHostnameCount);
	const { children } = props;

	if (hostnameLimit !== 0 && hostnameCount >= hostnameLimit) {
		return;
	}

	const handleVisibilityChange = (newVisibility: boolean) => {
		setModalVisible(newVisibility);
		setSubmitted(false);
	};

	const handleModalSubmitted = (event: FormEvent<IHostnameFormElement>) => {
		event.preventDefault();
		setSubmitted(true);

		const hostname = event?.currentTarget?.hostname?.value;
		const ipv4 = event?.currentTarget?.ipv4?.value;
		const ipv6 = event?.currentTarget?.ipv6?.value;

		if (!hostname || !(ipv4 || ipv6)) {
			notify(NotificationType.ERROR, "A hostname and IPv4 or IPv6 address is required.");

			return;
		}

		createHostname({
			hostname,
			ipv4,
			ipv6
		})
			.unwrap()
			.then(() => handleVisibilityChange(false))
			.catch((error: SerializedError) => {
				setErrors(JSON.parse(error.message));
			});
	};

	const modalTitle = (
		<Fragment>
			<Icon
				name="server"
				fixedWidth
				iconWeight={ IconWeight.LIGHT }
			/>
			<span>Add Hostname</span>
		</Fragment>
	);

	const modalContent = (
		<form
			id="hostame-form"
			onSubmit={ handleModalSubmitted }
			className="hostname-form"
			noValidate
		>
			<div className="label">Hostname</div>
			<Input
				name="hostname"
				required
				noValidate={ !submitted }
				validityMessage={ errors["hostname"] }
				validator={ () => !errors["hostname"] }
			/>
			<div className="label">IPv4</div>
			<Input
				name="ipv4"
				noValidate={ !submitted }
				validityMessage={ errors["ipv4"] }
				validator={ () => !errors["ipv4"] }
			/>
			<div className="label">IPv6</div>
			<Input
				name="ipv6"
				noValidate={ !submitted }
				validityMessage={ errors["ipv6"] }
				validator={ () => !errors["ipv6"] }
			/>
			<Button htmlType="submit" className="action-button">Submit</Button>
		</form>
	);

	return (
		<Modal
			title={ modalTitle }
			modalContent={ modalContent }
			visible={ modalVisible }
			onVisibilityChange={ handleVisibilityChange }
			className="hostname-modal"
		>
			{ children }
		</Modal>
	);
};

export default memo(HostnameModal);
