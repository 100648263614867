// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.edit-user-modal-content .edit-user-form .form-input {
  margin-bottom: 10px;
}
.edit-user-modal-content .edit-user-form .form-buttons-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Components/EditUserModal/EditUserModal.less"],"names":[],"mappings":"AAEA;EAGG,mBAAA;AAHH;AAAA;EAOG,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,WAAA;AAJH","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.edit-user-modal-content {\n\t.edit-user-form {\n\t\t.form-input {\n\t\t\tmargin-bottom: 10px;\n\t\t}\n\n\t\t.form-buttons-container {\n\t\t\tdisplay: flex;\n\t\t\tjustify-content: space-between;\n\t\t\tmargin-top: 10px;\n\t\t\twidth: 100%;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
