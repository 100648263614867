import { IIndexSignature } from "@clintonelec/typescriptutils";
import { POST, GET, PATCH, DELETE } from "Data/API/Request";
import { serializeJsonApiData, deserializeJsonApiData } from "Data/Utils/JsonApi";
import { IDataPage, IHostname, IWithApiData, ResourceType } from "Interfaces";

export const createHostname = (hostname: Partial<IHostname>) => {
	const serializedPayload = serializeJsonApiData(ResourceType.HOSTNAMES, hostname);

	return POST("hostnames", serializedPayload).then((response: IWithApiData<IHostname>) => {
		return deserializeJsonApiData<IHostname>(response);
	});
};

export const updateHostname = (hostname: IHostname) => {
	const { id } = hostname;
	const serializedPayload = serializeJsonApiData(ResourceType.HOSTNAMES, hostname);

	return PATCH(`hostnames/${ id }`, serializedPayload).then((response: IWithApiData<IHostname>) => {
		return deserializeJsonApiData<IHostname>(response);
	});
};

export const fetchHostname = (id: string) => {
	return GET(`hostnames/${ id }`).then((response: IWithApiData<IHostname>) => {
		return deserializeJsonApiData<IHostname>(response);
	});
};

export const fetchHostnames = (filter: IIndexSignature<string | number>) => {
	return GET("hostnames", filter).then((response: IWithApiData<IHostname[]>) => {
		return deserializeJsonApiData<Partial<IHostname[]>>(response).then((hosts: Partial<IHostname[]>) => {
			return {
				...response,
				data: hosts
			} as IDataPage<IHostname>;
		});
	});
};

export const deleteHostname = (id: string) => {
	return DELETE(`hostnames/${ id }`);
};
