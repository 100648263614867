import {
	Action, AnyAction, configureStore, EnhancedStore, MiddlewareArray, ReducersMapObject,
	createAsyncThunk as createAsyncThunkBase
} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistCombineReducers, PersistConfig } from "redux-persist";
import { ThunkMiddleware } from "redux-thunk";
import { IAppState } from "Interfaces";
import { TypedUseSelectorHook, useSelector as reduxUseSelector, useDispatch as reduxUseDispatch } from "react-redux";

// type output from configure store, typed explicitly here as this has an impact on how our store.dispatch can be used
/* eslint-disable @typescript-eslint/no-explicit-any */
export let store: EnhancedStore<any, Action<any>, MiddlewareArray<[(store: any) => (next: any) => (action: any) => any,
	ThunkMiddleware<any, AnyAction, undefined>]>>;
/* eslint-enable @typescript-eslint/no-explicit-any */

const persistConfig: PersistConfig<IAppState> = {
	key: "root",
	storage
};

export const buildStore = (reducers: ReducersMapObject, middleware) => {
	store = configureStore({
		reducer: persistCombineReducers(persistConfig, reducers),
		middleware: (getDefaultMiddleware) => getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false
		})
			.prepend(
				middleware
			)
	});
};

export const createAsyncThunk = createAsyncThunkBase.withTypes<{ state: IAppState }>();
export const useAppSelector: TypedUseSelectorHook<IAppState> = reduxUseSelector;
export const useAppDispatch: () => typeof store.dispatch = reduxUseDispatch;
