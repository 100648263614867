import { ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { getActiveToken, getActiveUser } from "Data/Selectors/User";
import { IAppState } from "Interfaces";
import { REHYDRATE } from "redux-persist";
import { pusherConnection } from "src/Pusher";

export const appRehydratedListener = (listenerMiddleware: ListenerMiddlewareInstance<IAppState>) => {
	listenerMiddleware.startListening(
		{
			type: REHYDRATE,
			effect: async (action, listenerApi) => {
				const state = listenerApi.getState();
				const user = getActiveUser(state);
				const token = getActiveToken(state);

				if (user && token) {
					pusherConnection.initializeConnection();
				}
			}
		}
	);
};
