// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Images/Clinton_Logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-page {
  top: 2vh;
  width: 100%;
}
.login-page .forgot-password {
  color: #8e8e8e;
  cursor: pointer;
  margin-top: 1em;
}
.login-page .logo {
  background-image: radial-gradient(circle at 0 200px, rgba(0 211 255 / 100%) 0%, rgba(0 255 255 / 100%) 0%, rgba(0 113 186 / 100%) 55%);
  height: 5em;
  margin-bottom: 1vh;
  mask: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center;
  padding: 10px;
  top: -2.75em;
  width: 100%;
}
.login-page .login-button {
  margin-top: 2em;
  width: 100%;
}
.login-page .login-button:hover,
.login-page .login-button:focus {
  background-color: #68d5ae;
  border-color: #68d5ae;
  color: white;
}
.login-page .login-button .icon {
  margin-right: 1em;
}
.login-page .login-input-email {
  margin-bottom: 7px;
}
.login-page .form-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.login-page .form-footer .form-link {
  color: #8e8e8e;
  cursor: pointer;
  margin-top: 1em;
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Auth/LoginPage/LoginPage.less"],"names":[],"mappings":"AAIA;EACC,QAAA;EACA,WAAA;AAHD;AACA;EAKE,cAAA;EACA,eAAA;EACA,eAAA;AAHF;AAJA;EAWE,sIAAA;EAOA,WAAA;EACA,kBAAA;EACA,8DAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;AAVF;AAbA;EA2BE,eAAA;EACA,WAAA;AAXF;AAaE;;EAEC,yBAAA;EACA,qBAAA;EACA,YAAA;AAXH;AAvBA;EAsCG,iBAAA;AAZH;AA1BA;EA2CE,kBAAA;AAdF;AA7BA;EA+CE,aAAA;EACA,8BAAA;EACA,WAAA;AAfF;AAlCA;EAoDG,cAAA;EACA,eAAA;EACA,eAAA;EACA,qBAAA;AAfH","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n@import \"../../../Styles/colors.less\";\n\n.login-page {\n\ttop: 2vh;\n\twidth: 100%;\n\n\t.forgot-password {\n\t\tcolor: @gray-1;\n\t\tcursor: pointer;\n\t\tmargin-top: 1em;\n\t}\n\n\t.logo {\n\t\tbackground-image:\n\t\t\tradial-gradient(\n\t\t\t\tcircle at 0 200px,\n\t\t\t\trgba(0 211 255 / 100%) 0%,\n\t\t\t\trgba(0 255 255 / 100%) 0%,\n\t\t\t\trgba(0 113 186 / 100%) 55%\n\t\t\t);\n\t\theight: 5em;\n\t\tmargin-bottom: 1vh;\n\t\tmask: url(\"../../../Images/Clinton_Logo.svg\") no-repeat center;\n\t\tpadding: 10px;\n\t\ttop: -2.75em;\n\t\twidth: 100%;\n\t}\n\n\t.login-button {\n\t\tmargin-top: 2em;\n\t\twidth: 100%;\n\n\t\t&:hover,\n\t\t&:focus {\n\t\t\tbackground-color: @success-light;\n\t\t\tborder-color: @success-light;\n\t\t\tcolor: white;\n\t\t}\n\n\t\t.icon {\n\t\t\tmargin-right: 1em;\n\t\t}\n\t}\n\n\t.login-input-email {\n\t\tmargin-bottom: 7px;\n\t}\n\n\t.form-footer {\n\t\tdisplay: flex;\n\t\tjustify-content: space-between;\n\t\twidth: 100%;\n\n\t\t.form-link {\n\t\t\tcolor: @gray-1;\n\t\t\tcursor: pointer;\n\t\t\tmargin-top: 1em;\n\t\t\ttext-decoration: none;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
