import { IIndexSignature } from "@clintonelec/typescriptutils";
import { ActionReducerMapBuilder, PayloadAction, createReducer } from "@reduxjs/toolkit";
import {
	createHostnameAction, updateHostnameAction, deleteHostnameAction, fetchHostnameAction, fetchHostnamesPageAction
} from "Data/Actions/Hostnames";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import {
	createOrUpdatePaginatedItem, createPaginatedItem, deletePaginatedItem, handleClearCache, handleDataPage,
	updatePaginatedItem
} from "Data/Utils/Redux";
import { IHostname, IPaginatedDataSet } from "Interfaces";

const { SET_HOSTNAME_OPTIONS } = ACTION_TYPES.Hostname;
const { CLEAR_CACHE } = ACTION_TYPES.System;
const defaultState: IPaginatedDataSet<IHostname> = {
	data: [ ],
	lastPage: -1,
	options: {
		"page[number]": 1
	}
};

const setHostnameOptionsReducer = (
	state: IPaginatedDataSet<IHostname>, action: PayloadAction<IIndexSignature<string | number>>
) => {
	state.options = action.payload;
	state.data = [];
	state.lastPage = -1;
};

const reducer = (reducerBuilder: ActionReducerMapBuilder<IPaginatedDataSet<IHostname>>) => {
	reducerBuilder.addCase(CLEAR_CACHE, handleClearCache(defaultState));
	reducerBuilder.addCase(createHostnameAction.fulfilled, createPaginatedItem);
	reducerBuilder.addCase(updateHostnameAction.fulfilled, updatePaginatedItem);
	reducerBuilder.addCase(deleteHostnameAction.fulfilled, deletePaginatedItem);
	reducerBuilder.addCase(fetchHostnameAction.fulfilled, createOrUpdatePaginatedItem);
	reducerBuilder.addCase(fetchHostnamesPageAction.fulfilled, handleDataPage);
	reducerBuilder.addCase(SET_HOSTNAME_OPTIONS, setHostnameOptionsReducer);
};

export default createReducer(defaultState, reducer);
