export enum PolicyType {
	TERMS_AND_CONDITIONS = "TERMS_AND_CONDITIONS",
	PRIVACY_POLICY = "PRIVACY_POLICY"
}

export enum PolicyTermType {
	PARAGRAPH = "paragraph",
	BULLETS = "bullets"
}

export interface ISection {
	title: string;
	data: ISubsection[];
}

export interface ISubsection {
	subtitle: string;
	data: ITerm[];
}

export interface ITerm {
	type: PolicyTermType;
	data: string[] | string;
}
