import { ListenerMiddlewareInstance } from "@reduxjs/toolkit";
import { IAppState } from "Interfaces";
import { userLoggedInPredicate } from "Data/Redux/Middleware/Predicates/User";
import { fetchHostnamesPageAction } from "Data/Actions/Hostnames";
import { pusherConnection } from "src/Pusher";

export const userLoggedInListener = (listenerMiddleware: ListenerMiddlewareInstance<IAppState>) => {
	listenerMiddleware.startListening(
		{
			predicate: userLoggedInPredicate,
			effect: async (action, listenerApi) => {
				pusherConnection.initializeConnection();
				listenerApi.dispatch(fetchHostnamesPageAction());
			}
		}
	);
};
