import { createAsyncThunk } from "Data/Redux/Store";
import { ACTION_TYPES } from "Data/Objects/ActionTypes";
import { IHostname, IWithApiData } from "Interfaces";
import * as HostnamesApi from "Data/API/Hostnames";
import { getHostnamesOptions } from "Data/Selectors/Hostnames";
import { createAction } from "@reduxjs/toolkit";
import { IIndexSignature, sanitizeEmptyStrings } from "@clintonelec/typescriptutils";
import { deserializeJsonApiData } from "Data/Utils/JsonApi";

const {
	CREATE_HOSTNAME, UPDATE_HOSTNAME, DELETE_HOSTNAME, FETCH_HOSTNAME, FETCH_HOSTNAME_PAGE,
	SET_HOSTNAME_OPTIONS
} = ACTION_TYPES.Hostname;

export const setHostnameOptionsAction = createAction<IIndexSignature<string | number>>(SET_HOSTNAME_OPTIONS);

export const createHostnameAction = createAsyncThunk(
	CREATE_HOSTNAME,
	async (payload: Partial<IHostname>) => {
		const sanitizedPayload = sanitizeEmptyStrings(payload);

		return HostnamesApi.createHostname(sanitizedPayload);
	}
);

export const updateHostnameAction = createAsyncThunk(
	UPDATE_HOSTNAME,
	async (payload: IHostname) => {
		const sanitizedPayload = sanitizeEmptyStrings(payload);

		return HostnamesApi.updateHostname(sanitizedPayload);
	}
);

export const deleteHostnameAction = createAsyncThunk(
	DELETE_HOSTNAME,
	async (id: string) => {
		return HostnamesApi.deleteHostname(id)
			.then(() => {
				return id;
			});
	}
);

export const fetchHostnameAction = createAsyncThunk(
	FETCH_HOSTNAME,
	async (id: string) => {
		return HostnamesApi.fetchHostname(id);
	}
);

export const fetchHostnamesPageAction = createAsyncThunk(
	FETCH_HOSTNAME_PAGE,
	async (payload, thunkApi) => {
		return HostnamesApi.fetchHostnames(getHostnamesOptions(thunkApi.getState()));
	}
);

// #region Local Actions for Pusher

export const createHostnameLocalAction = createAsyncThunk(
	CREATE_HOSTNAME,
	async (payload: IWithApiData<IHostname>) => {
		return deserializeJsonApiData<IHostname>(payload);
	}
);

export const updateHostnameLocalAction = createAsyncThunk(
	UPDATE_HOSTNAME,
	async (payload: IWithApiData<Partial<IHostname>>) => {
		return deserializeJsonApiData<Partial<IHostname>>(payload);
	}
);

export const deleteHostnameLocalAction = createAsyncThunk(
	DELETE_HOSTNAME,
	async (id: string) => {
		return id;
	}
);

// #endregion
