import { memo } from "react";
import UserPopover from "Components/UserPopover";
import "Components/TitleBar/TitleBar.less";
import ClintonLogoSVG from "Images/Clinton_Logo.svg";
import { useNavigate } from "react-router-dom";
import Icon, { IconSize } from "Components/Icon";

const TitleBar = () => {
	const navigate = useNavigate();
	const navigateToHome = () => navigate("");

	return (
		<div className="title-bar">
			<img src={ ClintonLogoSVG } className="title-bar-logo" onClick={ navigateToHome } />
			<div className="title-bar-right">
				<a
					className="help-link"
					href="https://support.clintonelectronics.com"
					target="_blank"
					rel="noreferrer"
				>
					<Icon
						className="help-icon title-icon"
						name="question-circle"
						fixedWidth
						size={ IconSize.SMALLER }
					/>
				</a>
				<UserPopover />
			</div>
		</div>
	);
};

export default memo(TitleBar);
