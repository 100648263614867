// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-page {
  background: radial-gradient(circle at 0 200px, rgba(0 211 255 / 100%) 0%, rgba(0 255 255 / 100%) 0%, rgba(0 113 186 / 100%) 55%);
  display: flex;
  height: 100%;
  width: 100%;
}
.auth-page .center-content {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: #00000059 0 7px 29px 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
.auth-page .center-content .login-content {
  align-items: center;
  display: flex;
  padding: 8%;
}
.auth-page .login-footer {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
}
.auth-page .login-footer .footer-row {
  color: #ffffff;
  font-size: smaller;
  margin-bottom: 5px;
}
.auth-page .login-footer .footer-privacy {
  display: flex;
  margin-bottom: 10px;
}
.auth-page .login-footer .footer-privacy div {
  cursor: pointer;
}
.auth-page .login-footer .footer-privacy div:first-child {
  margin-right: 15px;
}
.auth-page .login-footer .footer-copyright {
  font-size: x-small;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Auth/AuthPage/AuthPage.less"],"names":[],"mappings":"AAIA;EACC,gIAAA;EAOA,aAAA;EACA,YAAA;EACA,WAAA;AATD;AADA;EAaE,mBAAA;EACA,mBAAA;EACA,kCAAA;EACA,SAAA;EACA,kBAAA;EACA,QAAA;EACA,gCAAA;EACA,YAAA;AATF;AAXA;EAuBG,mBAAA;EACA,aAAA;EACA,WAAA;AATH;AAhBA;EA8BE,mBAAA;EACA,aAAA;EACA,OAAA;EACA,sBAAA;EACA,yBAAA;AAXF;AAvBA;EAqCG,cAAA;EACA,kBAAA;EACA,kBAAA;AAXH;AA5BA;EA2CG,aAAA;EACA,mBAAA;AAZH;AAhCA;EA+CI,eAAA;AAZJ;AAcI;EACC,kBAAA;AAZL;AAtCA;EAwDG,kBAAA;AAfH","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n@import \"../../../Styles/colors.less\";\n\n.auth-page {\n\tbackground:\n\t\tradial-gradient(\n\t\t\tcircle at 0 200px,\n\t\t\trgba(0 211 255 / 100%) 0%,\n\t\t\trgba(0 255 255 / 100%) 0%,\n\t\t\trgba(0 113 186 / 100%) 55%\n\t\t);\n\tdisplay: flex;\n\theight: 100%;\n\twidth: 100%;\n\n\t.center-content {\n\t\tbackground: #ffffff;\n\t\tborder-radius: 10px;\n\t\tbox-shadow: #00000059 0 7px 29px 0;\n\t\tleft: 50%;\n\t\tposition: absolute;\n\t\ttop: 50%;\n\t\ttransform: translate(-50%, -50%);\n\t\twidth: 500px;\n\n\t\t.login-content {\n\t\t\talign-items: center;\n\t\t\tdisplay: flex;\n\t\t\tpadding: 8%;\n\t\t}\n\t}\n\n\t.login-footer {\n\t\talign-items: center;\n\t\tdisplay: flex;\n\t\tflex: 1;\n\t\tflex-direction: column;\n\t\tjustify-content: flex-end;\n\n\t\t.footer-row {\n\t\t\tcolor: #ffffff;\n\t\t\tfont-size: smaller;\n\t\t\tmargin-bottom: 5px;\n\t\t}\n\n\t\t.footer-privacy {\n\t\t\tdisplay: flex;\n\t\t\tmargin-bottom: 10px;\n\n\t\t\tdiv {\n\t\t\t\tcursor: pointer;\n\n\t\t\t\t&:first-child {\n\t\t\t\t\tmargin-right: 15px;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\n\t\t.footer-copyright {\n\t\t\tfont-size: x-small;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
