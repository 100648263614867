import { createSelector } from "@reduxjs/toolkit";
import { IAppState, IUserState } from "Interfaces";

const getUserState = (state: IAppState) => {
	return state?.User;
};

const getUser = (state: IUserState) => {
	return state?.user;
};

const getToken = (state: IUserState) => {
	return state?.token;
};

const getPusherKey = (state: IUserState) => {
	return state?.pusherKey;
};

const getHostnameLimit = (state: IUserState) => {
	return state?.hostnameLimit;
};

export const getActiveUser = createSelector(
	getUserState,
	getUser
);

export const getActiveToken = createSelector(
	getUserState,
	getToken
);

export const selectPusherKey = createSelector(
	getUserState,
	getPusherKey
);

export const selectHostnameLimit = createSelector(
	getUserState,
	getHostnameLimit
);
