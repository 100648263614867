import { isEqual } from "lodash";

export const valueChangedPredicate = (selector) => (action, currentState, previousState) => {
	const oldValue = selector(previousState);
	const newValue = selector(currentState);

	return oldValue !== newValue;
};

export const valueDeepChangedPredicate = (selector) => (action, currentState, previousState) => {
	const oldValue = selector(previousState);
	const newValue = selector(currentState);

	return !isEqual(oldValue, newValue);
};
