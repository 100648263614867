import { Popover, PopoverPlacement, PopoverTrigger } from "@clintonelec/react-storybook";
import { memo } from "react";
import { getActiveUser } from "Data/Selectors/User";
import { logoutUserRemoteAction } from "Data/Actions/User";
import { clearCacheAction } from "Data/Actions/System";
import Icon, { IconSize, IconWeight } from "Components/Icon";
import "Components/UserPopover/UserPopover.less";
import { useAppDispatch, useAppSelector } from "Data/Redux/Store";
import EditUserModal from "Components/EditUserModal";

const UserPopover = () => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(getActiveUser);
	const { name } = user;
	const logout = () => dispatch(logoutUserRemoteAction());
	const clearCache = () => dispatch(clearCacheAction());

	const handleLogout = () => {
		logout().then(clearCache);
	};

	const popoverContent = (
		<div className="user-popover-content-wrapper">
			<Popover
				className="user-popover-full-name"
				content={ name }
				trigger={ PopoverTrigger.HOVER }
				placement={ PopoverPlacement.BOTTOM }
				hideOnClick={ true }
				interactive={ false }
			>
				<div className="user-popover-name">
					{ name }
				</div>
			</Popover>
			<EditUserModal user={ user }>
				<div className="user-popover-row">
					<Icon
						className="edit-settings-icon"
						fixedWidth
						name="pencil"
						iconWeight={ IconWeight.LIGHT }
					/>
					Edit User Settings
				</div>
			</EditUserModal>
			<div className="user-popover-row" onClick={ handleLogout }>
				<Icon
					className="sign-out-icon"
					fixedWidth
					name="sign-out"
					iconWeight={ IconWeight.LIGHT }
				/>
				<span>Log Out</span>
			</div>
		</div>
	);

	return (
		<Popover
			className="user-popover"
			content={ popoverContent }
			trigger={ PopoverTrigger.CLICK }
			placement={ PopoverPlacement.BOTTOM_END }
			arrow={ false }
		>
			<Icon
				className="title-icon"
				name="user"
				fixedWidth
				size={ IconSize.SMALLER }
			/>
		</Popover>
	);
};

export default memo(UserPopover);
