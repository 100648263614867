import { clearCacheAction } from "Data/Actions/System";
import { logoutUserLocalAction } from "Data/Actions/User";

export const logoutOnUnauthorized = store => next => action => {
	if (action.type?.includes("rejected") && action.payload === 401) {
		store.dispatch(logoutUserLocalAction())
			.then(() => {
				store.dispatch(clearCacheAction());
			});
	}

	return next(action);
};
