import { getActiveToken, getActiveUser } from "Data/Selectors/User";
import { valueDeepChangedPredicate } from "Data/Redux/Middleware/Predicates";

export const userLoggedInPredicate = (action, currentState, previousState) => {
	const token = getActiveToken(currentState);
	const newUser = getActiveUser(currentState);
	const userChanged = valueDeepChangedPredicate(getActiveUser)(action, currentState, previousState);

	return userChanged && !!newUser && !!token;
};
