// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-bar {
  background: #127ec0;
  color: #ffffff;
  display: flex;
  height: 50px;
  justify-content: space-between;
  padding-right: 20px;
}
.title-bar .title-bar-logo {
  margin-left: 20px;
  width: 200px;
}
.title-bar .title-bar-logo:hover {
  cursor: pointer;
}
.title-bar .title-bar-right {
  align-items: center;
  cursor: pointer;
  display: flex;
}
.title-bar .title-bar-right a {
  color: inherit;
  text-decoration: inherit;
}
.title-bar .title-bar-right .title-icon:hover {
  color: #b6b8bc;
  cursor: pointer;
}
.title-bar .title-bar-right .help-icon {
  padding-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/TitleBar/TitleBar.less"],"names":[],"mappings":"AAEA;EACC,mBAAA;EACA,cAAA;EACA,aAAA;EACA,YAAA;EACA,8BAAA;EACA,mBAAA;AADD;AALA;EASE,iBAAA;EACA,YAAA;AADF;AAGE;EACC,eAAA;AADH;AAZA;EAkBE,mBAAA;EACA,eAAA;EACA,aAAA;AAHF;AAjBA;EAuBG,cAAA;EACA,wBAAA;AAHH;AAOG;EACC,cAAA;EACA,eAAA;AALJ;AAzBA;EAmCG,mBAAA;AAPH","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.title-bar {\n\tbackground: @primary;\n\tcolor: #ffffff;\n\tdisplay: flex;\n\theight: 50px;\n\tjustify-content: space-between;\n\tpadding-right: 20px;\n\n\t.title-bar-logo {\n\t\tmargin-left: 20px;\n\t\twidth: 200px;\n\n\t\t&:hover {\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\n\t.title-bar-right {\n\t\talign-items: center;\n\t\tcursor: pointer;\n\t\tdisplay: flex;\n\n\t\ta {\n\t\t\tcolor: inherit;\n\t\t\ttext-decoration: inherit;\n\t\t}\n\n\t\t.title-icon {\n\t\t\t&:hover {\n\t\t\t\tcolor: @gray-0;\n\t\t\t\tcursor: pointer;\n\t\t\t}\n\t\t}\n\n\t\t.help-icon {\n\t\t\tpadding-right: 20px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
