// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hostname-modal {
  display: flex;
  flex-direction: column;
}
.hostname-modal .hostname-form {
  display: flex;
  flex-direction: column;
}
.hostname-modal .hostname-form .cec-input-wrapper {
  margin-bottom: 5px;
}
.hostname-modal .hostname-form .action-button {
  align-self: flex-end;
}
.hostname-modal .hostname-form .label {
  margin-right: 5px;
}
.hostname-modal .hostname-form .cec-button {
  margin-top: 15px;
}
.hostname-modal .cec-modal-title span:first-child {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/HostnameModal/HostnameModal.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,sBAAA;AADD;AADA;EAKE,aAAA;EACA,sBAAA;AADF;AALA;EASG,kBAAA;AADH;AARA;EAaG,oBAAA;AAFH;AAXA;EAiBG,iBAAA;AAHH;AAdA;EAqBG,gBAAA;AAJH;AAjBA;EA0BE,kBAAA;AANF","sourcesContent":["@import (reference) \"../../Styles/colors\";\n\n.hostname-modal {\n\tdisplay: flex;\n\tflex-direction: column;\n\n\t.hostname-form {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\n\t\t.cec-input-wrapper {\n\t\t\tmargin-bottom: 5px;\n\t\t}\n\n\t\t.action-button {\n\t\t\talign-self: flex-end;\n\t\t}\n\n\t\t.label {\n\t\t\tmargin-right: 5px;\n\t\t}\n\n\t\t.cec-button {\n\t\t\tmargin-top: 15px;\n\t\t}\n\t}\n\n\t.cec-modal-title span:first-child {\n\t\tmargin-right: 10px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
