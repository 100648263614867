// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policy-modal .term {
  margin-bottom: 10px;
}
.policy-modal .container {
  height: 500px;
  overflow-y: auto;
  padding-right: 20px;
}
.cec-modal-light.policy-modal {
  color: #7e7e7e;
}
.cec-modal-light.policy-modal h1,
.cec-modal-light.policy-modal h2 {
  color: #7e7e7e;
}
.cec-modal-dark.policy-modal {
  color: #8e8e8e;
}
.cec-modal-dark.policy-modal h1,
.cec-modal-dark.policy-modal h2 {
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Global/PolicyModal/PolicyModal.less"],"names":[],"mappings":"AAEA;EAEE,mBAAA;AAFF;AAAA;EAME,aAAA;EACA,gBAAA;EACA,mBAAA;AAHF;AAOA;EACC,cAAA;AALD;AAIA;;EAKE,cAAA;AALF;AASA;EACC,cAAA;AAPD;AAMA;;EAKE,YAAA;AAPF","sourcesContent":["@import (reference) \"../../../Styles/colors\";\n\n.policy-modal {\n\t.term {\n\t\tmargin-bottom: 10px;\n\t}\n\n\t.container {\n\t\theight: 500px;\n\t\toverflow-y: auto;\n\t\tpadding-right: 20px;\n\t}\n}\n\n.cec-modal-light.policy-modal {\n\tcolor: @light-gray-2;\n\n\th1,\n\th2 {\n\t\tcolor: @light-gray-2;\n\t}\n}\n\n.cec-modal-dark.policy-modal {\n\tcolor: @gray-1;\n\n\th1,\n\th2 {\n\t\tcolor: white;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
