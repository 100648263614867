import { PayloadAction } from "@reduxjs/toolkit";
import { IDataPage, IPaginatedDataSet, IWithId } from "Interfaces";
import { assign } from "lodash";

export const handleClearCache = <T>(defaultState: T) => () => {
	return defaultState;
};

export function getPaginatedDataIndex<T extends IWithId>(state: IPaginatedDataSet<T>, item: T) {
	return state?.data?.findIndex((currItem: T) => {
		return currItem.id === item?.id;
	});
}

export function getIndexByUUID<T extends IWithId>(state: IPaginatedDataSet<T>, id: string) {
	return state?.data?.findIndex((currItem: T) => {
		return currItem.id === id;
	});
}

export function deletePaginatedItems<T extends IWithId>(state: IPaginatedDataSet<T>,
	action: PayloadAction<string[]>) {

	if (action?.payload) {
		const newArray = state.data.filter(device => {
			return !action.payload.includes(device.id);
		});

		state.data = newArray;
	}
}

export function deletePaginatedItem<T extends IWithId>(state: IPaginatedDataSet<T>,
	action: PayloadAction<string>) {

	if (action?.payload) {
		const { payload } = action;
		const index = getIndexByUUID(state, payload);

		if (index >= 0) {
			state.data.splice(index, 1);
		}
	}
}

export function createPaginatedItem<T extends IWithId>(state: IPaginatedDataSet<T>, action: PayloadAction<T>) {
	if (!action?.payload) {
		return;
	}

	const index = getPaginatedDataIndex(state, action.payload);

	if (index < 0) {
		state.data.push(action.payload);
	}
}

export function updatePaginatedItems<T extends IWithId>(state: IPaginatedDataSet<T>, action: PayloadAction<T[]>) {
	if (!action?.payload) {
		return;
	}

	const { payload } = action;

	payload.forEach((item: T) => {
		updatePaginatedItem(state, { payload: item, type: action.type });
	});
}

export function updatePaginatedItem<T extends IWithId>(state: IPaginatedDataSet<T>, action: PayloadAction<T>) {
	if (!action?.payload) {
		return;
	}

	const { payload } = action;
	const index = getPaginatedDataIndex(state, payload);

	if (index >= 0 && payload) {
		assign(state.data[index], payload);
	}
}

export function createOrUpdatePaginatedItem<T extends IWithId>(state: IPaginatedDataSet<T>,
	action: PayloadAction<T>) {
	if (!action?.payload) {
		return;
	}

	const index = getPaginatedDataIndex(state, action.payload);

	if (index < 0) {
		createPaginatedItem(state, action);
	} else {
		updatePaginatedItem(state, action);
	}
}

export function createOrUpdatePaginatedItems<T extends IWithId>(
	state: IPaginatedDataSet<T>, action: PayloadAction<T[]>
) {
	if (action?.payload) {
		const { payload } = action;

		payload.forEach((item) => {
			const index = state.data.findIndex(currentItem => {
				return currentItem.id === item.id;
			});

			if (index < 0) {
				state.data.push(item);
			} else {
				assign(state.data[ index ], item);
			}
		});
	}
}

export function handleDataPage<T extends IWithId>(state: IPaginatedDataSet<T>, action: PayloadAction<IDataPage<T>>) {
	const pageNumberExp = new RegExp("page%5Bnumber%5D=([0-9]+)");
	const pageUrl = action?.payload?.links?.next ?? action?.payload?.links?.last;
	const nextPage = pageNumberExp.exec(pageUrl)?.[1] ?? +state.options["page[number]"] + 1;

	createOrUpdatePaginatedItems(state, { payload: action?.payload?.data, type: action.type });

	if (state.lastPage === -1) {
		state.lastPage = +pageNumberExp.exec(action?.payload?.links?.last)[1];
		state.options[ "page[number]" ] = +nextPage;
	} else {
		state.options[ "page[number]" ] = +nextPage;
	}
}

export function getPaginatedData<T extends IWithId>(state: IPaginatedDataSet<T>) {
	return state?.data;
}

export function filterPaginatedDataByUuid<T extends IWithId>(state: IPaginatedDataSet<T>, id: string) {
	return state?.data.find((item: T) => {
		return item.id === id;
	});
}

export function getPaginatedDataOptions<T extends IWithId>(state: IPaginatedDataSet<T>) {
	return state?.options;
}
