import { FormEvent, memo, useState } from "react";
import { Button, ButtonTypes, ConfirmModal, Input, Modal, IIcon } from "@clintonelec/react-storybook";
import { IUpdatePasswordPayload, IUpdateUserPayload, IUser, IWithChildren } from "Interfaces";
import { escapeRegExp } from "lodash";
import "Components/EditUserModal/EditUserModal.less";
import { deleteUserAccountAction, updateAccountPasswordAction, updateUserAccountAction } from "Data/Actions/User";
import { useAppDispatch } from "Data/Redux/Store";
import Icon from "Components/Icon";
import { isEmptyString } from "@clintonelec/typescriptutils";
import { NotificationType, notify } from "src/Notifications";

interface IEditUserModalProps extends IWithChildren {
	user: IUser;
}

interface IAccountFormFields extends HTMLFormElement {
	email: HTMLInputElement;
	newName: HTMLInputElement;
	username: HTMLInputElement;
	currentPassword: HTMLInputElement;
	newPassword: HTMLInputElement;
	confirmNewPassword: HTMLInputElement;
}

const deleteIcon: IIcon = {
	name: "trash"
};

const updateIcon: IIcon = {
	name: "save"
};

const passwordValidator = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$";

const EditUserModal = ({ children, user: { name, email, username } }: IEditUserModalProps) => {
	const dispatch = useAppDispatch();
	const [ modalVisible, setModalVisible ] = useState<boolean>(false);
	const [ submitted, setSubmitted ] = useState<boolean>(false);
	const [ newPassword, setNewPassword ] = useState<string>("");
	const updateUserAccount = (user: IUpdateUserPayload) => dispatch(updateUserAccountAction(user));
	const changeAccountPassword = (payload: IUpdatePasswordPayload) => dispatch(updateAccountPasswordAction(payload));
	const deleteUserAccount = () => dispatch(deleteUserAccountAction);
	const editingPassword = !isEmptyString(newPassword);

	const handleVisibilityChange = (newVisibility: boolean) => {
		setModalVisible(newVisibility);
		setSubmitted(false);
	};

	const handleAccountFormSubmit = (event: FormEvent<IAccountFormFields>) => {
		event.preventDefault();
		setSubmitted(true);

		if (event.currentTarget.checkValidity()) {
			const newEmail = event.currentTarget.email?.value;
			const newName = event.currentTarget.newName?.value;
			const newUsername = event.currentTarget.username?.value;
			const currentPassword = event.currentTarget.currentPassword?.value;
			const confirmNewPassword = event.currentTarget.confirmNewPassword?.value;

			if (newName !== name || newEmail !== email || newUsername !== username) {
				updateUserAccount({ email: newEmail, name: newName, username: newUsername });
			}

			if (editingPassword) {
				changeAccountPassword({
					currentPassword,
					password: newPassword,
					passwordConfirmation: confirmNewPassword
				}).unwrap().then(() => {
					notify(NotificationType.SUCCESS, "Successfully updated your password.");
				}, () => {
					notify(NotificationType.ERROR, "Unable to update your password.");
				});
			}

			handleVisibilityChange(false);
		}
	};

	const editUserModalContent = (
		<div className="edit-user-modal-content">
			<form
				noValidate
				className="edit-user-form"
				onSubmit={ handleAccountFormSubmit }
			>
				<label className="form-label">Username</label>
				<Input
					name="username"
					wrapClassName="form-input"
					defaultValue={ username }
					required
					noValidate={ !submitted }
					validator="^[a-z]+([-_\.]?[a-z0-9]+)*$"
					validityMessage="You must enter a valid username"
				/>
				<label className="form-label">Email</label>
				<Input
					name="email"
					wrapClassName="form-input"
					defaultValue={ email }
					required
					noValidate={ !submitted }
					validityMessage="You must enter a valid email"
				/>
				<label className="form-label">Name</label>
				<Input
					name="newName"
					wrapClassName="form-input"
					defaultValue={ name }
					required
					noValidate={ !submitted }
				/>
				<label className="form-label">Current Password</label>
				<Input
					name="currentPassword"
					password
					wrapClassName="form-input"
					validator={ passwordValidator }
					validityMessage="Your password must meet the requirements"
					noValidate={ !editingPassword || !submitted }
					required={ editingPassword }
				/>
				<label className="form-label">New Password</label>
				<Input
					name="newPassword"
					password
					wrapClassName="form-input"
					onUpdate={ setNewPassword }
					validator={ passwordValidator }
					validityMessage="Your password must meet the requirements"
					noValidate={ !submitted }
				/>
				<label className="form-label">Confirm New Password</label>
				<Input
					name="confirmNewPassword"
					password
					wrapClassName="form-input"
					validator={ `${ escapeRegExp(newPassword) }` }
					validityMessage="Your passwords must match"
					noValidate={ !editingPassword || !submitted }
					required={ editingPassword }
				/>
				<div className="form-buttons-container">
					<ConfirmModal
						title="Delete Account"
						icon={ <Icon name={ deleteIcon.name } className="danger-icon" /> }
						okButton={ { text: "Confirm", type: ButtonTypes.DANGER, onClick: deleteUserAccount } }
						cancelButton={ { text: "Cancel" } }
					>
						<Button
							className="form-button"
							type={ ButtonTypes.DANGER }
							icon={ deleteIcon }
						>
							Delete Account
						</Button>
					</ConfirmModal>
					<Button
						className="form-button"
						icon={ updateIcon }
					>
						Update Account
					</Button>
				</div>
			</form>
		</div>
	);

	return (
		<Modal
			width={ 400 }
			title="Edit User Settings"
			modalContent={ editUserModalContent }
			visible={ modalVisible }
			onVisibilityChange={ handleVisibilityChange }
		>
			{ children }
		</Modal>
	);
};

export default memo(EditUserModal);
