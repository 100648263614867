import { createListenerMiddleware } from "@reduxjs/toolkit";
import { userLoggedInListener } from "Data/Redux/Middleware/Listeners/User";
import { appRehydratedListener } from "Data/Redux/Middleware/Listeners/System";

const listenerMiddleware = createListenerMiddleware();

[ userLoggedInListener, appRehydratedListener ].forEach(listener => {
	listener(listenerMiddleware);
});

export default listenerMiddleware;
